import { PageProps, graphql, Link } from "gatsby";
import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Prism from "prismjs";
import dayjs from "dayjs";

import ReadOthers from "../components/readOthers";
import SEO from "../components/seo";
import { AllDirectusMettanBlog, BlogArticle } from "../models";
import { resumeHtmlContent } from "../utils";
import home from "../images/home.png";
import { InlineShareButtons } from "sharethis-reactjs";

const BlogPost: React.FC<PageProps<AllDirectusMettanBlog, BlogArticle>> = data => {
  const {
    pageContext: { title, content, id, created_on, illustration, url },
    data: { allDirectusMettanBlog },
  } = data;
  const readOthers = allDirectusMettanBlog.nodes.filter(i => i.id != id);

  React.useEffect(() => Prism.highlightAll());

  return (
    <>
      <SEO
        title={title}
        description={resumeHtmlContent(content)}
        image={illustration.data.full_url}
      />
      <Container fluid style={{ maxWidth: 1400 }}>
        <Row>
          <Col xl={10} md={9} sm={12}>
            <Card className="blog-post">
              <div className="home-link">
                <Link to="/">
                  <img src={`${home}`} className="home-icon" alt="home" />
                </Link>
              </div>
              <img src={illustration.data.full_url} className="img-header" alt="header" />
              <Card.Body>
                <h1 className="blog-post-title">
                  {title}
                  <small>{dayjs(created_on).format("YYYY-MM-DD HH:mm")}</small>
                </h1>
                <div className="sharethis-inline-share-buttons">
                  <InlineShareButtons
                    config={{
                      url: `https://mettan.dev${url}`,
                      title,
                      image: illustration.data.full_url,
                      description: resumeHtmlContent(content),
                      alignment: "center",
                      color: "social",
                      enabled: true,
                      font_size: 16,
                      labels: "cta",
                      language: "en",
                      networks: ["whatsapp", "linkedin", "messenger", "facebook", "twitter"],
                      padding: 12,
                      radius: 4,
                      show_total: true,
                      size: 40,
                    }}
                  />
                </div>
                <div
                  className="blog-post-content"
                  dangerouslySetInnerHTML={{
                    __html: `<div> ${content} </div>`,
                  }}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col xl={2} md={3} sm={12}>
            <div className="other-articles">
              <span>Recent articles</span>
              <ReadOthers articles={readOthers} />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export const query = graphql`
  query pageQuery {
    allDirectusMettanBlog(sort: { fields: created_on, order: DESC }, limit: 5) {
      nodes {
        id
        url
        created_on
        title
        content
        illustration {
          data {
            full_url
            thumbnails {
              url
              dimension
            }
          }
        }
      }
    }
  }
`;

export default BlogPost;
