import { Link } from "gatsby";
import React from "react";
import { Card } from "react-bootstrap";
import { BlogArticle } from "../models";

interface Props {
  articles: BlogArticle[];
}

const getThumbnailSrc = (article: BlogArticle) =>
  article.illustration.data.thumbnails.find(t => t.dimension === "800x600").url;

const ReadOthers = ({ articles }: Props) => {
  return (
    <>
      {articles.map(article => {
        return (
          <Link to={article.url} key={article.id}>
            <Card style={{ marginBottom: 10 }}>
              <img
                src={getThumbnailSrc(article)}
                alt={`thumbail-${article.id}`}
                style={{ width: "100%" }}
              />
              <Card.Body className="read-other-card">
                <p>{article.title}</p>
              </Card.Body>
            </Card>
          </Link>
        );
      })}
    </>
  );
};

export default ReadOthers;
